<template>
  <b-form>
    <b-form-group
      label-class="font-weight-bold"
      :label="$t('email')"
      label-for="email"
    >
      <b-form-input
        id="email"
        v-model="email"
        @input="email = email.toLowerCase()"
      ></b-form-input>
    </b-form-group>
    <b-form-group
      label-class="font-weight-bold"
      :label="$t('password')"
      label-for="password"
      class="password__input"
    >
      <b-input-group id="password">
        <b-form-input
          v-model="password"
          :type="passwordInputType"
          class="border-right-0"
        ></b-form-input>
        <b-input-group-append is-text>
          <b-icon
            :icon="passwordIcon"
            class="clickable"
            @click="changePasswordInputState"
          ></b-icon>
        </b-input-group-append>
        <b-input-group-append>
          <b-button
            variant="outline-secondary"
            class="input-append-btn"
            @click="passwordRevovery"
          >
            {{ $t("button.forgot") }}
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
    <b-form-checkbox v-model="rememberMe">
      {{ $t("rememberMe") }}
    </b-form-checkbox>
    <b-row class="no-gutters  mt-5">
      <b-button
        block
        variant="red"
        :disabled="!isFormValid"
        @click="login"
        class="mb-1"
      >
        {{ $t("button.login") }}
      </b-button>
    </b-row>
  </b-form>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      password: "",
      showPassword: false,
      rememberMe: false
    };
  },
  methods: {
    login() {
      this.$store.commit("loader/SET_LOADER", {});
      this.$store
        .dispatch("clearAll", null, { root: true })
        .then(() => {
          return this.$store.dispatch("auth/login", {
            email: this.email,
            password: this.password
          });
        })
        .catch(() => {
          console.log("error");
        })
        .finally(() => this.$store.dispatch("loader/close"));
    },
    changePasswordInputState() {
      this.showPassword = !this.showPassword;
    },
    passwordRevovery() {
      this.$router.push({ name: "PasswordRecovery" });
    }
  },
  computed: {
    isFormValid() {
      return this.email.length > 0 && this.password.length > 0;
    },
    passwordInputType() {
      return this.showPassword ? "text" : "password";
    },
    passwordIcon() {
      return this.showPassword ? "eye" : "eye-slash";
    }
  }
};
</script>

<style scoped>
.input-append-btn {
  border: 1px solid #ced4da;
}
.password__input >>> .input-group-text {
  background-color: white;
  border-left: unset;
}
.password__input >>> input {
  min-width: 160px;
}
</style>
