<template>
  <div class="background">
    <b-row class="no-gutters mx-auto justify-content-center pt-5 pb-5">
      <b-col class="col-12 col-md-8 col-lg-6 col-xl-4 p-1">
        <b-card no-body class="py-2 px-3 border-0 rounded-0 bg-transparent">
          <div style="wdith: 100%; text-align: center">
            <img
              style="width: 250px"
              src="../assets/images/Logo_EmPower-2020.png"
              alt="Image"
              class="rounded-0"
            />
          </div>
          <!-- <h6 class="mb-0 text-red font-weight-bold text-uppercase">
            {{ $t("welcome") }}
          </h6>
          <hr class="my-2 mx-n3" /> -->
          <b-row class="no-gutters mt-4">
            <!-- <b-col class="col-12 col-sm-auto">
              <img
                style="width: 100px"
                src="../assets/images/Emotiva-logo-dash.png"
                alt="Image"
                class="rounded-0"
              />
            </b-col> -->
            <b-col class="col-12 col-sm">
              <b-card-body class="pt-sm-0 pl-sm-3 px-0 pb-1">
                <login-form />
              </b-card-body>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import LoginForm from "./../components/LoginForm";
export default {
  components: {
    LoginForm
  }
};
</script>

<style scoped>
.background {
  background: url("../assets/images/Login-page-network.png");
  background-size: 500px;
  background-repeat: no-repeat;
  background-position: right 0 top 0;
}
</style>
